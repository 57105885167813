<template>
    <settings-wrapper>
        <template v-slot:child>
            <div><archives-header /></div>
            <div class=" w-90 center mt4">
                <div class="pa3 scrollmenu">
                    <table v-cloak v-if="entities?.data?.length" cellspacing="0" class="table radius8">
                        <thead>
                            <tr style="background-color: #F4F7FF;">
                                <th class="w-10 tl borderTop">ID</th>
                                <th class="tl borderTop">Entity Name</th>
                                <th class="tl borderTop">Email</th>
                                <th class="tl borderTop">Phone No</th>
                                <th class="tl borderTop">Role</th>
                                <th class="tl borderTop">Deleted At</th>
                                <th class="tl borderTop">Deleted By</th>
                                <th class="w-10 tl borderTop">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(entity, index) in entities?.data" :key="index">
                                <td class="borderTop">{{ entity._id?.toUpperCase().slice(19, entity._id.length) }}</td>
                                <td class="tl flex items-center borderTop">
                                    <!-- <img
                                        :src="require('@/assets/images/profile-user.png')"
                                        class="pr1 h-10 w-10"
                                        style="width: 20px; margin-right: 10px"
                                        :alt="entity?.fullName || entity?.company"
                                    /> -->
                                    <span v-if="entity?.lastName">
                                        {{
                                            `${entity?.firstName?.trim()} ${entity?.lastName?.trim()}` || entity?.company?.trim()
                                        }}
                                    </span>

                                    <span v-else>
                                        {{ `${entity?.firstName?.trim()}` || entity?.company?.trim() }}
                                    </span>
                                </td>
                                <td class="borderTop">{{ entity?.email || '-'}}</td>
                                <td class="borderTop">{{ entity?.phone || '-'}}</td>
                                <td class="borderTop">
                                    <div
                                        class="flex items-center justify-center br-pill ph2 pv1"
                                        style="background: #0186d3; color: #ffffff"
                                        :style="setBGAndTextColor(entity)"
                                    >
                                        <span class="pa0" v-if="entity?.roles && entity?.roles?.length > 1">
                                            {{ `${entity?.roles?.[0]} + ${entity?.roles?.length - 1}` || '-' }}
                                        </span>
                                        <span class="pa0 ttc" v-else>{{ entity?.roles?.[0] || '-' }}</span>
                                    </div>
                                </td>
                                <td class="tl borderTop">{{ formatDate(entity?.deletedAt) }}</td>
                                <td class="tl borderTop">{{ entity?.deletedBy?.name || '-' }}</td>
                                <td class="tc borderTop">
                                    <a href="" @click.prevent="restoreEntity(entity._id)" title="Restore">
                                        <img :src="require('@/assets/images/Reload.svg')" alt="restore" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <TableEmptyState v-else :data-array="archives" />
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { formatDate } from '@/utils/lib'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import ArchivesHeader from '@/views/Settings/widgets/ArchivesHeader'
import TableEmptyState from '@/components/TableEmptyState'

export default {
    name: 'AccountSettings',
    components: { ArchivesHeader, SettingsWrapper, TableEmptyState },

    setup() {
        const store = useStore()
        const entities = computed(() => store?.state.Entity.archivedEntities)

        const initialState = () => ({
            stockLevel: 1
        })
        const state = reactive(initialState())

        const setBGAndTextColor = entityItem => {
            const item = entityItem?.roles?.[0]

            switch (item) {
                case 'customer':
                    return 'background-color: #edf0fd; color: #132c8c;'

                case 'vendor':
                    return 'background-color: #ce541b; color: white;'

                case 'debtor':
                    return 'background-color: #14c773; color: white;'

                case 'investor':
                    return 'background-color: #132c8c; color: white;'

                case 'creditor':
                    return 'background-color: #eb2d36; color: white;'

                case 'supplier':
                    return 'background-color: #662deb; color: white;'

                default:
                    return 'background-color: #662deb; color: white;'
            }
        }

        const entityRoles = ['customer', 'vendor', 'debtor', 'investor', 'creditor', 'supplier']

        const restoreEntity = id => {
            store.dispatch('Entity/restoreEntity', id)
        }

        onMounted(() => {
            store.dispatch('Entity/getArchivedEntities')
        })

        return {
            state,
            entities,
            entityRoles,
            setBGAndTextColor,
            restoreEntity,
            formatDate
        }
    }
}
</script>

<style scoped>
#button-update {
    position: relative;
    padding: 15px 50px;
    margin: 20px;
    /* display: flex; */
    /* justify-content: flex-end; */
}
.borderTop{
    border-top: none !important;
    border-bottom: none !important;
}
</style>
